
/* eslint-disable */
// Autogenerated
// -------------
// gulpfile.ts/wasm.ts --> generateBuildInfo

export const Build = {
    version: "8.3.3 (7ddb3e8e0f7752722bcdfbb92f435489)",
    buildSeed: 1729849181563,
    "wdosbox-x.wasm": {
        "size": 6294401,
        "gzSize": 2293134
    },
    "wdosbox-x.js": {
        "size": 225479,
        "gzSize": 53220
    },
    "wdosbox.wasm": {
        "size": 1479838,
        "gzSize": 504506
    },
    "wdosbox.js": {
        "size": 103037,
        "gzSize": 27449
    },
    "wlibzip.wasm": {
        "size": 112732,
        "gzSize": 52239
    },
    "wlibzip.js": {
        "size": 74570,
        "gzSize": 19814
    }
,
};
